import Swiper, { Navigation, Pagination, Autoplay, Mousewheel, FreeMode, Thumbs, Lazy } from 'swiper';
import 'swiper/css/bundle';

import "./appointment-booker";
import './vue-search-form';
import './cta-sidebar';
import './link-popup';
import './content-block-usp-panel';

import LazyLoad from 'vanilla-lazyload';
window['ll'] = new LazyLoad();
window.addEventListener("load", (event) => {
    if (window.location.href.indexOf("/inspiration/") == -1) {
        console.log("getting images");
        window.setTimeout(() => window['ll'].loadAll(), 8000);
    }
});

var pjson = require('../../package.json');

window['mapInit'] = window['mapInit'] || function () { };

class Init {
    version: string;
    mobileMenuTrigger: any;
    closeHeaderMenu: any;
    siteNav: any;
    destinationsTrigger: HTMLElement;
    destinationsTriggerMobile: HTMLElement;
    inspirationTrigger: HTMLElement;
    holidayTypeTrigger: HTMLElement;
    holidayTypeTriggerMobile: HTMLElement;
    mainMenu: HTMLElement;
    mainMenuClose: HTMLElement;
    menuBack: any;
    menuDestinations: any;
    menuInspiration: any;
    menuHolidayType: any;
    mainTextCard: any;
    mainNavLinks: any;
    mainNavItems: any;
    menuSwipers: any;
    swipers: any;
    mainNavSelector: any;
    body: HTMLBodyElement;
    theWindow: Window & typeof globalThis;
    scrollAmount: any;
    mobileFooterNavigation: any;
    mobileFooterToggle: any;
    mobileFooterList: any;
    clickedIndex: number;
    setLocation: boolean;
    setDates: boolean;
    setPax: boolean;
    setFlights: boolean;
    setClass: boolean;
    modalOpen: any;
    modalClose: any;
    modal: any;
    modalContent: any;
    siteHeader: HTMLElement;
    retailModal: any;
    retailModalContent: any;
    retailModalClose: any;
    retailModalOpen: any;
    tailormadeModal: any;
    tailormadeModalContent: any;
    tailormadeModalClose: any;
    tailormadeModalOpen: any;
    onRequestModal: any;
    onRequestModalContent: any;
    onRequestModalClose: any;
    onRequestModalOpen: any;

    suggestedTabs: NodeListOf<HTMLElement>;

    constructor() {
        this.getDOM();
        this.scrollHeader();
        this.menuCarousel();
        this.menu();
        this.footer();
        this.initModal();
        this.mixedListSwiper();
        this.suggestedTabsSwiper();
        this.initTabs();
        this.initSwipers();
        this.version = pjson.version;
    }

    getDOM() {
        Swiper.use([Navigation, Pagination, Mousewheel, FreeMode, Thumbs, Autoplay, Lazy]);
        this.mobileMenuTrigger = document.querySelector("#mobile-menu-trigger");
        this.closeHeaderMenu = document.querySelector("#close-header-menu");
        this.siteNav = document.querySelector("#site-nav");
        this.destinationsTrigger = document.getElementById("destinations-menu-trigger");
        this.destinationsTriggerMobile = document.getElementById("destinations-menu-trigger-mobile");
        this.inspirationTrigger = document.getElementById("inspiration-menu-trigger");
        this.holidayTypeTrigger = document.getElementById("holidaytype-menu-trigger");
        this.holidayTypeTriggerMobile = document.getElementById("holidaytype-menu-trigger-mobile");
        this.mainMenu = document.getElementById("menu");
        this.siteHeader = document.querySelector("header");
        this.mobileFooterNavigation = document.querySelector(".footer-navigation");

        this.suggestedTabs = document.querySelectorAll(".suggested-tabs");

        if (document.querySelector("#close-menu")) {
            this.mainMenuClose = document.getElementById("close-menu");
        }
        if (document.querySelector("#back")) {
            this.menuBack = this.mainMenu.querySelector("#back");
        }
        if (document.querySelector(".menu__destinations")) {
            this.menuDestinations = this.mainMenu.querySelector(".menu__destinations");
        }
        if (document.querySelector(".menu__holidaytype")) {
            this.menuHolidayType = this.mainMenu.querySelector(".menu__holidaytype");
        }
        if (document.querySelector(".menu__inspiration")) {
            this.menuInspiration = this.mainMenu.querySelector(".menu__inspiration");
        }
        if (document.querySelector(".text-card")) {
            this.mainTextCard = this.menuInspiration.querySelector(".text-card");
            this.mainNavLinks = this.mainTextCard.querySelectorAll("li");
            this.mainNavSelector = this.mainTextCard.querySelector(".selector");
            this.mainNavItems = this.menuInspiration.querySelectorAll(".nav-item");
            this.menuSwipers = this.menuInspiration.querySelector(".menu__swipers");
            this.swipers = this.menuSwipers.querySelectorAll(".menu-swiper");
        }

        this.body = document.querySelector("body");
        this.theWindow = window;
        this.scrollAmount;

        if (this.mobileFooterNavigation) {
            this.mobileFooterNavigation = document.querySelector(".footer-navigation__left");
            this.mobileFooterToggle = this.mobileFooterNavigation.querySelectorAll(".h6");
            this.mobileFooterList = this.mobileFooterNavigation.querySelectorAll("ul");
        }

        this.modal = document.querySelector(".modal.offer-modal");
        if (this.modal) {
            this.modalContent = this.modal.querySelector(".modal__content");
            this.modalClose = this.modalContent.querySelector(".modal-close");
            this.modalOpen = ".offer-modal-open";
        }
        this.onRequestModal = document.querySelector(".modal.onrequest-modal");
        if (this.onRequestModal) {
            this.onRequestModalContent = this.onRequestModal.querySelector(".modal__content");
            this.onRequestModalClose = this.onRequestModalContent.querySelector(".modal-close");
            this.onRequestModalOpen = ".onrequest-modal-open";
        }
        this.retailModal = document.querySelector(".modal.retail-modal");
        if (this.retailModal) {
            this.retailModalContent = this.retailModal.querySelector(".modal__content");
            this.retailModalClose = this.retailModalContent.querySelector(".modal-close");
            this.retailModalOpen = ".retail-modal-open";
        }
        this.tailormadeModal = document.querySelector(".modal.tailormade-modal");
        if (this.tailormadeModal) {
            this.tailormadeModalContent = this.tailormadeModal.querySelector(".modal__content");
            this.tailormadeModalClose = this.tailormadeModalContent.querySelector(".modal-close");
            this.tailormadeModalOpen = ".tailormade-modal-open";
        }
    }

    initModal() {
        if (this.modalOpen) {
            const openFunc = (e) => {
                const target = (e.target as HTMLElement).closest(this.modalOpen);
                if (target) {
                    window.kuoni.enquire.currentOfferDestinationName = target.dataset.destinationfilter;
                    this.modal.classList.add("active");
                    this.lockBody();
                }
            };

            document.addEventListener("click", openFunc);
            document.querySelector(".accom-list-server-rendered-cards")?.addEventListener("click", openFunc); // some components have bubble stoppers so we bind further down the dom.

            this.modalClose.addEventListener("click", () => { this.modal.classList.remove("active"); this.unlockBody(); });
            this.modal.addEventListener("click", () => { this.modal.classList.remove("active"); this.unlockBody(); });
            this.modalContent.addEventListener("click", e => e.stopPropagation());

            this.retailModalClose.addEventListener("click", () => { this.retailModal.classList.remove("active"); this.unlockBody(); });
            this.retailModal.addEventListener("click", () => { this.retailModal.classList.remove("active"); this.unlockBody(); });
            this.retailModalContent.addEventListener("click", e => e.stopPropagation());
        }
        if (this.retailModalOpen) {
            const openFunc = (e) => {
                const target = (e.target as HTMLElement).closest(this.retailModalOpen);
                if (target) {
                    window.kuoni.enquire.currentOfferDestinationName = target.dataset.destinationfilter;
                    this.retailModal.classList.add("active");
                    this.lockBody();
                }
            };

            document.addEventListener("click", openFunc);
            document.querySelector(".accom-list-server-rendered-cards")?.addEventListener("click", openFunc); // some components have bubble stoppers so we bind further down the dom.

            this.retailModalClose.addEventListener("click", () => { this.retailModal.classList.remove("active"); this.unlockBody(); });
            this.retailModal.addEventListener("click", () => { this.retailModal.classList.remove("active"); this.unlockBody(); });
            this.retailModalContent.addEventListener("click", e => e.stopPropagation());
        }
        if (this.tailormadeModalOpen) {
            const openFunc = (e) => {
                const target = (e.target as HTMLElement).closest(this.tailormadeModalOpen);
                if (target) {
                    window.kuoni.enquire.currentOfferDestinationName = target.dataset.destinationfilter;
                    this.tailormadeModal.classList.add("active");
                    this.lockBody();
                }
            };

            document.addEventListener("click", openFunc);
            document.querySelector(".accom-list-server-rendered-cards")?.addEventListener("click", openFunc); // some components have bubble stoppers so we bind further down the dom.

            this.tailormadeModalClose.addEventListener("click", () => { this.tailormadeModal.classList.remove("active"); this.unlockBody(); });
            this.tailormadeModal.addEventListener("click", () => { this.tailormadeModal.classList.remove("active"); this.unlockBody(); });
            this.tailormadeModalContent.addEventListener("click", e => e.stopPropagation());
        }
        if (this.onRequestModalOpen) {
            const openFunc = (e) => {
                const target = (e.target as HTMLElement).closest(this.onRequestModalOpen);
                if (target) {
                    this.openOnRequestModal();
                }
            };
            document.addEventListener("click", openFunc);
            document.querySelectorAll(".room-details")?.forEach(e => e?.addEventListener("click", openFunc)); // some components have bubble stoppers so we bind further down the dom.

            this.onRequestModalClose.addEventListener("click", () => { this.onRequestModal.classList.remove("active"); this.unlockBody(); });
            this.onRequestModal.addEventListener("click", () => { this.onRequestModal.classList.remove("active"); this.unlockBody(); });
            this.onRequestModalContent.addEventListener("click", e => e.stopPropagation());
        }

        function toggleOpeningHours(e) {
            e.target.closest("p").parentElement.querySelector(".hours-reveal").classList.toggle("active");
        };
        document.querySelectorAll('.toggleOpeningHours').forEach(e => {
            e.addEventListener('click', toggleOpeningHours);
        });
    }

    initSwipers() {        
        new Swiper(".offset-carousel__swiper", {
            slidesPerView: "auto",
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            //breakpoints: {
            //    700: {
            //        slidesPerView: "auto",
            //        spaceBetween: 16,
            //        navigation: {
            //            nextEl: '.swiper-button-next',
            //            prevEl: '.swiper-button-prev',
            //        },
            //    }
            //},
        });

        new Swiper(".our-picks-swiper", {
            slidesPerView: 1.2,
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next-picks',
                prevEl: '.swiper-button-prev-picks',
            },
            pagination: {
                el: ".swiper-pagination-picks",
            },
           
            breakpoints: {
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.swiper-button-next-picks',
                        prevEl: '.swiper-button-prev-picks',
                    },
                    pagination: {
                       el: ".swiper-pagination-picks",
                    },
                },
                1100: {
                    slidesPerView: 2.2,
                    spaceBetween: 25,
                    navigation: {
                        nextEl: '.swiper-button-next-picks',
                        prevEl: '.swiper-button-prev-picks',
                    },
                    pagination: {
                       el: ".swiper-pagination-picks",
                    },
                },
                1300: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                    navigation: {
                        nextEl: '.swiper-button-next-picks',
                        prevEl: '.swiper-button-prev-picks',
                    },
                    pagination: {
                       el: ".swiper-pagination-picks",
                    },
                }
            },
        });

        if(document.querySelector(".content-block-offers .offers-swiper")){
            new Swiper(".content-block-offers .offers-swiper:not(.swiper-initialized)", {
                slidesPerView: 'auto',
                spaceBetween: 16,
                navigation: {
                    nextEl: '.swiper-button-next-offers',
                    prevEl: '.swiper-button-prev-offers',
                },
                pagination: {
                    el: ".swiper-pagination-offers",
                },
                breakpoints: {
                    1260: {
                        slidesPerView: 4,
                        spaceBetween: 24,
                        navigation: {
                            nextEl: '.swiper-button-next-offers',
                            prevEl: '.swiper-button-prev-offers',
                        },
                        pagination: {
                            el: ".swiper-pagination-offers",
                        },
                    },
                }
            });
        }

        new Swiper(".uspthree-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next-uspthree',
                prevEl: '.swiper-button-prev-uspthree',
            },
            breakpoints: {
                860: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                    navigation: {
                        nextEl: '.swiper-button-next-suggested',
                        prevEl: '.swiper-button-prev-suggested',
                    },
                },
            }
        });

        new Swiper(".suggested-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next-suggested',
                prevEl: '.swiper-button-prev-suggested',
            },
            breakpoints: {
                1260: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                    navigation: {
                        nextEl: '.swiper-button-next-suggested',
                        prevEl: '.swiper-button-prev-suggested',
                    },
                },
            }
        });

        new Swiper(".multi-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next-multi',
                prevEl: '.swiper-button-prev-multi',
            },
            breakpoints: {
                1260: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                    navigation: {
                        nextEl: '.swiper-button-next-multi',
                        prevEl: '.swiper-button-prev-multi',
                    },
                },
            }
        });
    }

    closeOnRequestModal() {
        this.onRequestModal.classList.remove("active");
        this.unlockBody();
    }
    openOnRequestModal() {
        this.onRequestModal.classList.add("active");
        this.lockBody();
    }

    initTabs() {
        if (!this.suggestedTabs)
            return;

        this.suggestedTabs.forEach(tab => {
            let firstLink = document.querySelector(".suggested-tab-trigger.active");
            let contents = tab.closest('.suggested-tabs-swiper').parentNode.querySelectorAll('.suggested-tab');
            let links = tab.querySelectorAll(".suggested-tab-trigger");
            links?.forEach(link => {
                link.addEventListener("click", () => {
                    contents?.forEach(content => content.classList.remove("active"));
                    links?.forEach(l => l.classList.remove("active"));

                    let index = Array.from(links).indexOf(link);
                    link.classList.add('active');
                    contents[index].classList.add("active");
                });
            });
        });
    }

    unlockBody() {
        this.body.classList.remove("lock");
        this.body.removeAttribute('style');
        this.theWindow.scrollTo(0, this.scrollAmount);
    }

    lockBody() {
        this.scrollAmount = this.theWindow.scrollY;
        this.body.classList.add("lock");
        this.body.style.top = -this.scrollAmount + "px";
    }

    scrollHeader() {
        if (this.siteHeader) {
            if (window.scrollY > 80) {
                this.siteHeader.classList.add("scrolled");
                document.body.classList.add('scrolled');
            } else if (window.scrollY < 20) {
                document.body.classList.remove('scrolled');
                this.siteHeader.classList.remove("scrolled");
            }
        }
    }

    mixedListSwiper() {
        new Swiper(".mixed-list-swiper", {
            slidesPerView: "auto",
            spaceBetween: 24,
            navigation: {
                nextEl: `.swiper-button-next-ml-cards`,
                prevEl: `.swiper-button-prev-ml-cards`,
            },
        });
    }

    suggestedTabsSwiper() {
        new Swiper(".suggested-tabs-swiper", {
            slidesPerView: "auto",
            spaceBetween: 10,
            freeMode: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                500: {
                    slidesPerView: "auto",
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                },
                800: {
                    slidesPerView: "auto",
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }
            },
        });
    }

    menuCarousel() {
        if (this.swipers) {
            this.swipers.forEach((swiper, i) => {
                new Swiper(swiper, {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    allowTouchMove: false,
                    mousewheel: true,
                    noSwiping: true,
                    noSwipingClass: 'swiper-slide',
                    navigation: {
                        nextEl: `.swiper-button-next-${i}`,
                        prevEl: `.swiper-button-prev-${i}`,
                    },

                    breakpoints: {
                        580: {
                            slidesPerView: "auto",
                            spaceBetween: 24,
                            allowTouchMove: true,
                            noSwiping: false,
                            navigation: {
                                nextEl: `.swiper-button-next-${i}`,
                                prevEl: `.swiper-button-prev-${i}`,
                            },
                        }
                    },
                });
            });

        }

        new Swiper(".menu-swiper-brochures", {
            slidesPerView: 1,
            spaceBetween: 0,
            mousewheel: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                580: {
                    slidesPerView: "auto",
                    spaceBetween: 36,
                    allowTouchMove: true,
                    noSwiping: false,
                    navigation: {
                        nextEl: `.swiper-button-next`,
                        prevEl: `.swiper-button-prev`,
                    },
                }
            },
        });

        new Swiper(".menu-swiper-collections", {
            slidesPerView: 1,
            spaceBetween: 0,
            mousewheel: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                580: {
                    slidesPerView: "auto",
                    spaceBetween: 13,
                    allowTouchMove: true,
                    noSwiping: false,
                    navigation: {
                        nextEl: `.swiper-button-next`,
                        prevEl: `.swiper-button-prev`,
                    },
                }
            },
        });

    }

    openMainMenu() {
        if (this.mainMenu) {
            this.mainMenu.classList.add("active");
            this.lockBody();
        }
    }

    closeMainMenu() {
        let to;
        this.siteNav.classList.remove("active"); //close header nav menu first
        to && clearTimeout(to);
        to = setTimeout(() => {
            this.mainMenu.classList.remove("active");
            this.menuInspiration.classList.remove("active");
            this.menuDestinations.classList.remove("active");
            this.menuSwipers.classList.remove("active");
            this.unlockBody();
        }, 210);
    }

    menuBackStep() {
        if (this.menuBack) {
            this.menuBack.addEventListener("click", () => {
                if (this.menuSwipers.classList.contains("active")) {
                    this.menuSwipers.classList.remove("active");
                } else {
                    this.mainMenu.classList.remove("active");
                }
            });
        }
    }

    menu() {
        let linkPos;
        let linkWidth;
        window.addEventListener("resize", () => {
            if (this.mainNavSelector) {
                if (window.innerWidth > 840) {
                    this.mainNavSelector.style.width !== 4 && (this.mainNavSelector.style.width = `${4}px`);
                    this.mainNavSelector.style.height !== 50 && (this.mainNavSelector.style.height = `${50}px`);
                    this.mainNavSelector.style.transform = `translateX(${0}px) translateY(${0}px)`;
                } else {
                    this.mainNavSelector.style.width !== 126 && (this.mainNavSelector.style.width = `${126}px`);
                    this.mainNavSelector.style.height !== 4 && (this.mainNavSelector.style.height = `${4}px`);
                    this.mainNavSelector.style.transform = `translateY(${0}px) translateY(${0}px)`;
                }
            }
        });

        if (this.mobileMenuTrigger) {
            this.mobileMenuTrigger?.addEventListener("click", () => { this.siteNav.classList.add("active"); this.lockBody(); });
            this.closeHeaderMenu?.addEventListener("click", () => { this.siteNav.classList.remove("active"); this.unlockBody(); });
            this.destinationsTrigger?.addEventListener("click", () => {
                if (this.menuInspiration) {
                    this.menuInspiration.classList.remove("active");
                }
                if (this.menuHolidayType) {
                    this.menuHolidayType.classList.remove("active");
                }
                this.openMainMenu();
                if (this.menuDestinations) {
                    this.menuDestinations.classList.add("active");
                }
            });
            this.destinationsTriggerMobile?.addEventListener("click", () => {
                if (this.menuInspiration) {
                    this.menuInspiration.classList.remove("active");
                }
                if (this.menuHolidayType) {
                    this.menuHolidayType.classList.remove("active");
                }
                this.openMainMenu();
                if (this.menuDestinations) {
                    this.menuDestinations.classList.add("active");
                }
            });
        }


        if (this.holidayTypeTrigger) {
            this.mobileMenuTrigger?.addEventListener("click", () => { this.siteNav.classList.add("active"); this.lockBody(); });
            this.closeHeaderMenu?.addEventListener("click", () => { this.siteNav.classList.remove("active"); this.unlockBody(); });
            this.holidayTypeTrigger?.addEventListener("click", () => {
                if (this.menuInspiration) {
                    this.menuInspiration.classList.remove("active");
                }
                if (this.menuDestinations) {
                    this.menuDestinations.classList.remove("active");
                }
                this.openMainMenu();
                if (this.menuHolidayType) {
                    this.menuHolidayType.classList.add("active");
                }
            });
            this.holidayTypeTriggerMobile?.addEventListener("click", () => {
                if (this.menuInspiration) {
                    this.menuInspiration.classList.remove("active");
                }
                if (this.menuDestinations) {
                    this.menuDestinations.classList.remove("active");
                }
                this.openMainMenu();
                if (this.menuHolidayType) {
                    this.menuHolidayType.classList.add("active");
                }
            });
        }


        if (this.inspirationTrigger) {
            this.inspirationTrigger.addEventListener("click", () => {
                if (this.menuDestinations) {
                    this.menuDestinations.classList.remove("active");
                }
                if (this.menuHolidayType) {
                    this.menuHolidayType.classList.remove("active");
                }
                this.openMainMenu();
                if (this.menuInspiration) {
                    this.menuInspiration.classList.add("active");
                }
            });
        }

        if (this.mainMenuClose) {
            this.mainMenuClose.addEventListener("click", () => {
                this.closeMainMenu();
            });
        }

        if (this.mainNavLinks) {
            this.mainNavLinks.forEach((link, i) => link.addEventListener("click", () => {
                this.mainNavLinks.forEach(l => l.classList.remove("active"));

                link.classList.add("active");
                this.mainNavItems.forEach(i => i.classList.remove("active"));
                this.mainNavItems[i].classList.add("active");

                if (window.innerWidth > 840) {
                    linkPos = link.offsetTop;
                    linkWidth !== 4 && (linkWidth = 4);
                    this.mainNavSelector.style.transform = `translateY(${linkPos - 8}px)`;
                    this.mainNavSelector.style.width = `${linkWidth}px`;
                } else {
                    linkPos = link.offsetLeft;
                    linkWidth = link.offsetWidth;
                    this.mainNavSelector.style.transform = `translateX(${linkPos}px)`;
                    this.mainNavSelector.style.width = `${linkWidth}px`;
                };

                if (window.innerWidth <= 580) {
                    this.menuSwipers.classList.add("active");
                }
            }));
        }

        window.addEventListener("scroll", () => {
            this.scrollHeader();
        });

        this.menuBackStep();
    }

    footer() {
        if (this.mobileFooterToggle) {
            this.mobileFooterToggle.forEach((link, i) => {
                link.addEventListener("click", () => {
                    if (window.innerWidth <= 550) {
                        link.classList.toggle("active");
                        let _this = this.mobileFooterList[i];
                        _this.style.height ? _this.style.height = "" : _this.style.height = "auto";
                    }
                });
            })
        }
    }


}



window["appComponent"] = new Init();

let respIq = document.querySelectorAll("#responseiq-app-container");
if (respIq.length) document.querySelector("main").classList.add("chat-offset");

